<div class="w-100 h-100">
    <header>
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow">
            <div class="container">
                <a class="navbar-brand" [routerLink]="['/']">
                    <img src="images/logo.png" alt="Deposition Prep Logo" class="dprep-logo" />
                    Depo Prep
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    </header>

    <div class="dprep-body-container d-flex">
        <div *ngIf="isAuthenticated"
             class="border-end d-flex dprep-left-navigation flex-column justify-content-between">
            <ul class="pt-2 list-unstyled w-100 dprep-left-navigation-menu">
                <li>
                    <a [routerLink]="['cases']" routerLinkActive="dprep-active">
                        <i class="fa-solid fa-folder-open"></i>
                        Cases
                    </a>
                </li>
                <li>
                    <a [routerLink]="['clients']" routerLinkActive="dprep-active">
                        <i class="fa-solid fa-building"></i>
                        Clients
                    </a>
                </li>
                <li>
                    <a [routerLink]="['admin']" routerLinkActive="dprep-active">
                        <i class="fa-solid fa-gear"></i>
                        Settings
                    </a>
                </li>
            </ul>

            <ul class="pt-2 list-unstyled w-100 dprep-left-navigation-menu">
                <li>
                    <a [routerLink]="['help']" routerLinkActive="dprep-active">
                        <i class="fa-regular fa-circle-question"></i>
                        Help Center
                    </a>
                </li>
                <li>
                    <a role="button" (click)="logout()">
                        <i class="fa-solid fa-right-from-bracket"></i>
                        Log Out
                    </a>
                </li>
            </ul>
        </div>

        <main class="overflow-auto"
              [class.dprep-has-left-navigation]="!isAuthenticated"
              dp-spinner-parent>
            <div class="p-3">
                <dp-loading-spinner *ngIf="isLoading" message="Logging you in..."></dp-loading-spinner>
                <router-outlet></router-outlet>
            </div>
        </main>
    </div>

    <footer class="d-flex align-items-center dprep-footer border-top pl-3 text-muted">
        <div class="container">
            &copy; 2024 - Deposition Prep
            <a href="https://google.com" target="_blank">Privacy</a>
        </div>
    </footer>
</div>