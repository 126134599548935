import { Component, OnInit } from '@angular/core';
import { AuthService, AuthState } from './shared/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  public isAuthenticated: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private readonly _authService: AuthService
  ) {

  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    const authState = await this._authService.getAuthState();

    if (authState == AuthState.LoggingIn || authState == AuthState.LoggedIn) {
      this.isAuthenticated = true;
      this.isLoading = false;
    } else {
      this._authService.signIn();
    }
  }

  public logout(): void {
    this._authService.signOut();
  }

  public editProfile(): void {
    this._authService.navigateToProfile();
  }
}
